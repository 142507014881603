



































import Vue from 'vue';
import Mapbox from 'mapbox-gl';
const vueMapbox = require('vue-mapbox');   // eslint-disable-line no-use-before-define


export default Vue.extend({
  name: 'Map',

  props: {
    extraLocations: {
      type: Array,
      required: false,
    },
    mapCenter: {
      type: Array,
      required: true,
    },
    mapZoom: {
      type: Number,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    zoomLevelOnClick :{
      type: Number,
      required: false,
      default: 15
    }
  },

  components: {
    MglMap: vueMapbox.MglMap,
    MglMarker: vueMapbox.MglMarker,
    MglPopup: vueMapbox.MglPopup,
  },

  data: () => ({
    // We use these local variables because we need to update which marker types are shown based on zoom level
    localZoomLevel: 0, // Need to initalise to something - will be updated on first render
    localLocations: [] as any[],
    localExtraLocations: [] as any[],
    selectedMarker: -1,
    accessToken: 'pk.eyJ1IjoibWF6dmVsIiwiYSI6ImNrMW1yM3lkdTAzaGgzbXRpa2IzenpzaTMifQ.Bre6UayEf7Tw0n1SCByZyw',
    mapStyle: 'mapbox://styles/mapbox/outdoors-v11',
    mapbox: Mapbox,
  }),

  created() {
      this.mapbox = Mapbox;
  },

  mounted() {
    this.localZoomLevel = this.mapZoom;
  },

  watch: {
    mapZoom() {
      this.localZoomLevel = this.mapZoom;
      if (this.localZoomLevel === this.zoomLevelOnClick) {
        // We just updated the ZoomLevel
        this.localLocations = [];
      }
    },
    locations() {
      this.localLocations = this.locations;
    },
    extraLocations() {
      this.localExtraLocations = this.extraLocations;
    },
  },

  methods: {
    onMapLoad(map: any) {
      map.map.on('zoomend', this.updateLocalZoom);
    },
    updateLocalZoom(e: any) {
      this.localZoomLevel = e.target.getZoom();
      if (this.localZoomLevel >= this.zoomLevelOnClick && this.localExtraLocations !== this.extraLocations) {
        // We have zoomed in more than 15. We need to update the locations if necessary.
        this.localExtraLocations = this.extraLocations;
        this.localLocations = [];
      } else if (this.localZoomLevel < this.zoomLevelOnClick) {
        // We have zoomed out to below 15. We need to update the locations if necessary.
        this.localLocations = this.locations;
        this.localExtraLocations = [];
      }
    },
  },

  head: {
      link: [
          { rel: 'stylesheet', href: 'https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css'},
      ],
  },
});
